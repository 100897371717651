import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { getOrganizationSchema } from './organization';

/**
 *
 * @param imageObject
 * @return {{}}
 */
export function getImageSchema(imageObject) {
  const {
    uri,
    title,
    author = getOrganizationSchema(),
    width,
    height,
    captionTranscript,
    isEdmundsPhoto,
  } = imageObject;
  return {
    '@context': SCHEMA_URL,
    '@type': 'ImageObject',
    contentUrl: uri,
    url: uri,
    width,
    height,
    name: title,
    creator: author,
    description: captionTranscript,
    ...(isEdmundsPhoto ? { creditText: 'Edmunds', copyrightNotice: 'Edmunds' } : {}),
  };
}
