import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import debounce from 'lodash/debounce';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getStaticImageUrl, getYoutubePreviewImage, YOUTUBE_PREVIEW_SIZE } from 'client/utils/image-helpers';
import { stripHtml } from 'client/utils/string-utils';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { YoutubeVideoModalAsync } from 'site-modules/shared/components/youtube-video-modal/youtube-video-modal-async';
import { Link } from 'site-modules/shared/components/link/link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './video-reviews.scss';

const YOUTUBE_THUMBNAIL_SIZE = {
  true: YOUTUBE_PREVIEW_SIZE.HQ,
  false: YOUTUBE_PREVIEW_SIZE.MQ,
};

export function VideoReviews({ className, vehicleName, shortVideos, creativeId, isMobile }) {
  const [modalVideoIndex, setModalVideoIndex] = useState(null);

  function onToggleVideoModal(event) {
    if (!get(event, 'currentTarget.dataset.index')) {
      setModalVideoIndex(null);
      return;
    }

    setModalVideoIndex(event.currentTarget.dataset.index);
  }

  const onScroll = debounce(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_VIEW_PHOTO,
        subaction_name: 'swipe_video_carousel',
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        creative_id: creativeId,
      },
    });
  }, 400);

  if (!shortVideos) {
    return null;
  }

  const modalVideoData = get(shortVideos, modalVideoIndex, {});

  return (
    <div className={classnames('video-reviews', className)} data-tracking-parent={creativeId}>
      <h2 className="heading-3 mb-0_5">{vehicleName} Video Shorts</h2>
      <div className="medium mb-1">
        by{' '}
        <Link
          className="test-team-link text-primary-darker text-underline"
          to="/car-reviews/features/how-we-test-cars-and-trucks.html"
        >
          Edmunds Test Team
        </Link>
      </div>
      <Row
        className={classnames(
          'thumbnails-row align-items-start flex-nowrap bg-cool-gray-90 py-1 px-0_25 mx-0 px-md-0_5 rounded-12',
          { 'long-list': shortVideos.length > 3 }
        )}
        onScroll={onScroll}
      >
        {shortVideos.map(({ imageUrlPortrait, videoDuration, label, title, videoIdPortrait }, index) => {
          const thumbnailImage = imageUrlPortrait
            ? getStaticImageUrl(imageUrlPortrait)
            : getYoutubePreviewImage(videoIdPortrait, YOUTUBE_THUMBNAIL_SIZE[isMobile]);

          return (
            <Col key={label} xs={5} md={4} className="d-flex">
              <button
                onClick={onToggleVideoModal}
                className="video-thumbnail w-100 border-0 p-0 no-focus"
                data-index={index}
                data-tracking-id="short_video_start"
                data-tracking-value={label}
                aria-label={
                  title
                    ? `Watch ${stripHtml(title)} video (${videoDuration} duration)`
                    : `Watch ${label} video review (${videoDuration} duration)`
                }
              >
                <AspectRatioContainer className="rounded-8" aspectRatio={isMobile ? '9:12' : '15:9'}>
                  <div
                    className="portrait-thumbnail pos-r h-100 d-flex justify-content-center"
                    style={isMobile || !imageUrlPortrait ? undefined : { backgroundImage: `url(${thumbnailImage})` }}
                  >
                    <img className="h-100" alt="" src={thumbnailImage} loading="lazy" />
                    <div className="overlay">
                      <div className="time-box rounded pos-a bottom-0 left-0 ml-0_5 mb-0_5 text-white bg-black fw-bold px-0_25 small">
                        <i className="icon-play41 size-10" aria-hidden /> {videoDuration}
                      </div>
                    </div>
                  </div>
                </AspectRatioContainer>
                <ContentFragment classes="label-text heading-6 mt-0_5 text-start">{title || label}</ContentFragment>
              </button>
            </Col>
          );
        })}
      </Row>
      <YoutubeVideoModalAsync
        onToggleModal={onToggleVideoModal}
        isOpen={!!modalVideoData.videoIdPortrait}
        videoAspectRatio="9:16"
        videoId={modalVideoData.videoIdPortrait}
        videoTitle={modalVideoData.label}
        thumbnailSrc={getStaticImageUrl(get(modalVideoData, 'imageUrlPortrait', ''))}
        creativeId="video-reviews-modal"
        className="video-review-modal m-auto h-100 d-flex align-items-center justify-content-center"
        contentClassName="m-0 pb-3 mx-1_25"
        closeTrackingId="video_close"
        closeTrackingValue={modalVideoData.videoTitle}
        closeTrackingParent={`video-${modalVideoData.videoIdPortrait}`}
      />
    </div>
  );
}

VideoReviews.propTypes = {
  shortVideos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      title: PropTypes.string,
      imageUrlPortrait: PropTypes.string,
      videoIdPortrait: PropTypes.string,
      videoDuration: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  vehicleName: PropTypes.string,
  creativeId: PropTypes.string,
  isMobile: PropTypes.bool,
};

VideoReviews.defaultProps = {
  shortVideos: null,
  className: null,
  vehicleName: null,
  creativeId: null,
  isMobile: false,
};
