import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Col from 'reactstrap/lib/Col';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';

export function HighlightCard({
  cardTag: CardTag,
  cardProps,
  className,
  label,
  children,
  minHeight,
  notAvailableText,
  isAvailable,
  isChildrenOnly,
}) {
  const scrollLinkProps =
    CardTag === ScrollLink
      ? {
          scrollConfig: { offset: SCROLL_OFFSET },
        }
      : {};

  return (
    <Col xs={6} md={3} className="px-0_25 px-md-0_5 px-lg-0_25 px-xl-0_5 mb-0_75 mb-md-1" style={{ minHeight }}>
      {isChildrenOnly ? (
        children
      ) : (
        <CardTag
          className={classnames(
            'd-flex flex-column highlight-card h-100 text-center py-1 px-0_5 px-lg-0_25 px-xl-0_5 rounded-8',
            { clickable: CardTag !== 'div' },
            className
          )}
          {...cardProps}
          {...scrollLinkProps}
        >
          <div className="h-50 fw-bold d-flex justify-content-center align-items-end">
            {isAvailable ? children : notAvailableText}
          </div>
          <div className="h-50 medium text-cool-gray-40 interaction-underline">{label}</div>
        </CardTag>
      )}
    </Col>
  );
}

HighlightCard.propTpyes = {
  cardTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  cardProps: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  minHeight: PropTypes.number,
  notAvailableText: PropTypes.string,
  isAvailable: PropTypes.bool,
  isChildrenOnly: PropTypes.bool,
};

HighlightCard.defaultProps = {
  cardTag: 'div',
  cardProps: null,
  className: null,
  label: null,
  children: null,
  minHeight: null,
  notAvailableText: 'not available',
  isAvailable: true,
  isChildrenOnly: false,
};
