import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';
import { get, orderBy, compact, isNumber, flow } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { connect } from 'react-redux';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildEvTestedDataPath,
  buildFuelCostsPath,
  buildPopularStylesPath,
  FeatureSpecsEntities,
  FeatureSpecsPaths,
  VehicleEntities,
  VehicleModel,
} from 'client/data/models/vehicle-v2';
import {
  ConsumerReviewsEntities,
  ConsumerReviewsModel,
  ConsumerReviewsPaths,
} from 'client/data/models/consumer-reviews';
import { buildSegmentRatingsPath, TypeRankingsFeaturesModel } from 'client/data/models/type-rankings-features';
import { getRepairPalDataPath, ReliabilityModel } from 'client/data/models/reliability';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { TypeRankingsEntities } from 'client/data/models/type-rankings-entities';
import { PageModel } from 'client/data/models/page';
import { EV_TESTED_DATA_START_DATE } from 'site-modules/shared/constants/range-and-cost';
import { MENU_HASH } from 'site-modules/core-page/utils/constants';
import { SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { getPlural } from 'client/utils/plural';
import { stripHtml } from 'client/utils/string-utils';
import { CorePageParams, getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { numberWithCommasForString } from 'site-modules/shared/utils/string';
import { formatStyleDetails, isAvailable } from 'site-modules/shared/utils/features-specs/utils';
import { getRatingScale } from 'site-modules/shared/utils/editorial-helper';
import { getBestSegmentRating } from 'site-modules/shared/utils/core-page/rating-and-ranking';
import { getCurrentStyleId } from 'site-modules/shared/utils/core-page/core-highlights';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { TruncatedTextLite } from 'site-modules/shared/components/truncated-text-lite/truncated-text-lite';
import { TypeRankingsLink } from 'site-modules/shared/components/type-rankings-link/type-rankings-link';
import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import { getEvRangeSource, getUsedRange, RANGE_SOURCE } from 'site-modules/shared/utils/core-page/ev-insights';
import { RatingBadge } from 'site-modules/shared/components/core-page/rating-badge/rating-badge';
import { HighlightCard } from './highlight-card/highlight-card';

import './core-highlights.scss';

const CREATIVE_ID = 'edmunds-highlights';
const MAX_CARDS_COUNT = 4;
const NO_MPG_NO_RANGE = 'no mpg or range';
const CARD_HEIGHT = {
  true: '136px',
  false: '100px',
};

export function CoreHighlightsUI({
  className,
  params,
  styles,
  sharedTrimName,
  editorialReview,
  reliabilityRating,
  segmentRating,
  consumerRatingsCount,
  prevYearConsumerRatingCount,
  stylesFeatures,
  fuelCostsData,
  evTestedRange,
  isUsed,
  isMobile,
  isPreprod,
  isCore6454Enabled,
}) {
  const { isElectric, isPluginHybrid } = params;
  const isEvDatapoints = isElectric || isPluginHybrid;

  const currentConsumerRatingCount = get(consumerRatingsCount, 'totalReviews')
    ? consumerRatingsCount
    : prevYearConsumerRatingCount;
  const consumerRating = get(currentConsumerRatingCount, 'ratingAggregation.averageStars');
  const consumerReviewsCount = get(currentConsumerRatingCount, 'totalReviews');
  const consumerReviewsLabel = `${consumerReviewsCount || ''} Owner ${getPlural('Review', consumerReviewsCount)}`;
  useEffect(() => {
    if (isNumber(consumerReviewsCount)) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'display_owner_rating',
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          creative_id: CREATIVE_ID,
          value: consumerReviewsCount,
        },
      });
    }
  }, [consumerReviewsCount]);

  useEffect(() => {
    if (isUsed && isNumber(reliabilityRating)) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'display_reliability_ratings',
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          creative_id: CREATIVE_ID,
          value: `${reliabilityRating}/5`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reliabilityRating]);

  const typeRating = get(segmentRating, 'rank');
  useEffect(() => {
    if (isNumber(typeRating)) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'display_ymm_ranking',
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          creative_id: CREATIVE_ID,
          value: typeRating,
        },
      });
    }
  }, [typeRating]);

  const currentStyleId = getCurrentStyleId({ styles, sharedTrimName });
  const currentStyleFeatures = stylesFeatures && stylesFeatures.find(({ id }) => id === currentStyleId);
  const {
    mpg: { combined },
    electric: { timeToCharge, eRange, batteryWarranty },
    basicWarranty,
  } = formatStyleDetails(currentStyleFeatures);
  useEffect(() => {
    if (!isEvDatapoints) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'display_mpg_or_range',
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          creative_id: CREATIVE_ID,
          value: isAvailable(combined) ? combined : NO_MPG_NO_RANGE,
        },
      });
    }
  }, [isEvDatapoints, combined]);

  const reviewEstimatedRange = isCore6454Enabled ? editorialReview?.estimatedRange : 0;

  const currentRange = getUsedRange({
    totalRange: evTestedRange || parseInt(eRange, 10) || reviewEstimatedRange,
    year: +params.year,
    isUsed,
  });
  const evRangeSource = getEvRangeSource({
    isTested: !!evTestedRange,
    isEpa: !!parseInt(eRange, 10),
    isEstimated: !!reviewEstimatedRange,
  });
  useEffect(() => {
    if (isEvDatapoints) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'display_mpg_or_range',
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          creative_id: CREATIVE_ID,
          value: isAvailable(currentRange) ? `${evRangeSource},${currentRange}` : NO_MPG_NO_RANGE,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEvDatapoints, currentRange]);

  const editorialRating = get(editorialReview, 'ratings.overall.rating');
  const editorialOverallText = get(editorialReview, 'ratings.overall.text');
  const ratingDisclaimer = get(editorialReview, 'ratingDisclaimer');
  const ratingScale = getRatingScale(editorialReview);

  if ((isPreprod && !editorialOverallText) || (!isPreprod && !(styles && stylesFeatures))) {
    return null;
  }

  const evRangeValue = currentRange ? `${currentRange} mi` : 'N/A';
  const timeToChargeValue = !!timeToCharge && timeToCharge.replace('hr.', 'hrs');

  const basicWarrantyValue = numberWithCommasForString(basicWarranty.replace('yr.', 'yr ').replace('mi.', 'mi'));
  const batteryWarrantyValue = numberWithCommasForString(batteryWarranty.replace('yr.', 'yr ').replace('mi.', 'mi'));

  const costPerMonth = get(fuelCostsData, 'fuelCosts.costPerMonth', 0);

  const cardClassName = classnames({ 'bg-cool-gray-90': editorialOverallText, 'bg-white': !editorialOverallText });
  const cardMinHeight = CARD_HEIGHT[!editorialOverallText && !!editorialRating];

  const cards = compact([
    // 1 priority
    !editorialOverallText && !!editorialRating && (
      <HighlightCard key="editorial-rating" isChildrenOnly minHeight={cardMinHeight}>
        <RatingBadge
          className="bg-white px-1 py-1 rounded-8 clickable"
          ratingScale={ratingScale}
          editorialRating={editorialRating}
          isScrollLink
        />
      </HighlightCard>
    ),
    !!typeRating && (
      <HighlightCard
        key="type-rating"
        cardTag={TypeRankingsLink}
        cardProps={{ segmentRating }}
        className={cardClassName}
        label={`${segmentRating.editorialSegment.segmentRatings.filter(({ rating }) => rating).length} ${get(
          get(segmentRating, 'editorialSegment', {}),
          'displayName',
          ''
        )}`}
        minHeight={cardMinHeight}
      >
        Rank #{typeRating}
      </HighlightCard>
    ),
    <HighlightCard
      key="consumers-rating"
      cardTag={ScrollLink}
      cardProps={{
        to: 'subnav-consumer-reviews',
        'data-tracking-id': 'core_page_view_consumer_review',
        'data-tracking-value': consumerRating,
      }}
      className={cardClassName}
      label={consumerReviewsLabel}
      minHeight={cardMinHeight}
      notAvailableText="not yet rated"
      isAvailable={!!consumerReviewsCount}
    >
      {!!consumerRating && <RatingStars className="medium" rating={consumerRating} />}
    </HighlightCard>,
    // 2 priority
    !!reliabilityRating && (
      <HighlightCard
        key="reliability"
        cardTag={ScrollLink}
        cardProps={{
          'data-tracking-id': 'view_reliability_ratings',
          'data-tracking-value': `${reliabilityRating}/5`,
          to: 'subnav-reliability',
        }}
        className={cardClassName}
        label="Reliability"
        minHeight={cardMinHeight}
      >
        <div className="d-flex align-items-baseline justify-content-center">
          <span className="fw-bold">{reliabilityRating}</span>&nbsp;
          <span className="small">/&nbsp;5</span>
        </div>
      </HighlightCard>
    ),
    isEvDatapoints && (
      <HighlightCard
        key="ev-range"
        cardTag={ScrollLink}
        cardProps={{
          to: 'subnav-ev-insights',
          'data-tracking-id': 'view_ev_range',
          'data-tracking-value': evRangeValue,
        }}
        className={cardClassName}
        label={isUsed || evRangeSource === RANGE_SOURCE.ESTIMATED ? 'Estimated EV Range' : 'EV Range'}
        notAvailableText="not EPA tested"
        minHeight={cardMinHeight}
        isAvailable={isAvailable(currentRange)}
      >
        {evRangeValue}
      </HighlightCard>
    ),
    // 3 priority
    !isEvDatapoints && (
      <HighlightCard
        key="mpg"
        cardTag={ScrollLink}
        cardProps={{
          to: 'subnav-features',
          'data-tracking-id': 'view_mpg',
          'data-tracking-value': `${combined} mpg`,
        }}
        className={cardClassName}
        label="Combined MPG"
        minHeight={cardMinHeight}
        isAvailable={isAvailable(combined)}
      >
        {combined} mpg
      </HighlightCard>
    ),
    isEvDatapoints && (
      <HighlightCard
        key="charging-time"
        cardTag={ScrollLink}
        cardProps={{
          to: 'subnav-ev-charging',
          'data-tracking-id': 'view_charging_time',
          'data-tracking-value': timeToChargeValue,
        }}
        className={cardClassName}
        label="Home Charging Time"
        minHeight={cardMinHeight}
        isAvailable={isAvailable(timeToCharge)}
      >
        {timeToChargeValue}
      </HighlightCard>
    ),
    // 4 priority
    isEvDatapoints && isUsed && (
      <HighlightCard
        key="battery-warranty"
        cardTag={ScrollLink}
        cardProps={{
          to: 'subnav-ev-insights',
          'data-tracking-id': 'view_ev_warranty',
          'data-tracking-value': batteryWarrantyValue,
        }}
        className={cardClassName}
        label="Original EV Warranty"
        minHeight={cardMinHeight}
        isAvailable={isAvailable(batteryWarranty)}
      >
        {batteryWarrantyValue}
      </HighlightCard>
    ),
    !isEvDatapoints && (
      <HighlightCard
        key="cost-to-drive"
        cardTag={ScrollLink}
        cardProps={{
          to: 'subnav-cost',
          'data-tracking-id': 'view_cost_to_drive',
          'data-tracking-value': `${getPriceString(costPerMonth)}/mo`,
        }}
        className={cardClassName}
        label="Cost to Drive"
        notAvailableText="not EPA tested"
        minHeight={cardMinHeight}
        isAvailable={!!costPerMonth}
      >
        {getPriceString(costPerMonth)}/mo
      </HighlightCard>
    ),
    !isEvDatapoints && isUsed && (
      <HighlightCard
        key="basic-warranty"
        cardTag={ScrollLink}
        cardProps={{
          to: 'subnav-features',
          'data-tracking-id': 'view_warranty',
          'data-tracking-value': basicWarrantyValue,
        }}
        className={cardClassName}
        label="Original Warranty"
        minHeight={cardMinHeight}
        isAvailable={isAvailable(basicWarranty)}
      >
        {basicWarrantyValue}
      </HighlightCard>
    ),
  ]);
  const cardsRow = !isPreprod && (
    <Fragment>
      <Row className="px-0_25 px-md-0">
        {/* Remove last card if count is odd. Maximum cards is 4 */}
        {cards.slice(0, Math.min(MAX_CARDS_COUNT, Math.floor(cards.length / 2) * 2))}
      </Row>
      {isUsed && (
        <div className="d-flex flex-column align-items-start">
          {!editorialOverallText && (
            <Button
              tag={ScrollLink}
              to={MENU_HASH.REVIEWS}
              scrollConfig={{ offset: SCROLL_OFFSET }}
              color="link"
              className="rounded medium mb-0_25 text-transform-none fw-normal text-cool-gray-40 px-0 py-0"
              data-tracking-id="view_edmunds_review"
            >
              Read full review
              <i className="icon-arrow-right3 text-primary-darker d-inline-block size-10 ml-0_25" aria-hidden />
            </Button>
          )}
          <Button
            tag={ScrollLink}
            to={MENU_HASH.FEATURES}
            scrollConfig={{ offset: SCROLL_OFFSET }}
            color="link"
            className="rounded medium mb-1 text-transform-none fw-normal text-cool-gray-40 px-0 py-0"
            data-tracking-id="view_features_specs"
          >
            See all features & specs
            <i className="icon-arrow-right3 text-primary-darker d-inline-block size-10 ml-0_25" aria-hidden />
          </Button>
        </div>
      )}
    </Fragment>
  );

  const ratingDisclaimerFragment = !!ratingDisclaimer && (
    <Fragment>
      {!isMobile && <hr className="mt-1 mb-0_75" />}
      {/* To not be crawled */}
      <style>
        {`
          .no-crawl::before {
            content: attr(data-content);
          }
        `}
      </style>
      <em className="no-crawl small mr-0_25" data-content={stripHtml(ratingDisclaimer)} />
    </Fragment>
  );

  const readFullReviewLink = !isUsed && (
    <ScrollLink
      to={MENU_HASH.RATING_SCORECARD}
      scrollConfig={{ offset: SCROLL_OFFSET }}
      className="rounded size-16 text-underline"
      data-tracking-id="view_edmunds_review"
    >
      Read full review
    </ScrollLink>
  );

  return (
    <div
      className={classnames(
        'core-highlights bg-cool-gray-90 rounded-12',
        {
          'pb-1': editorialOverallText,
        },
        className
      )}
      data-tracking-parent={CREATIVE_ID}
    >
      {!!editorialOverallText && (
        <div className="bg-white px-1 pt-1 pb-0_25 pb-md-0 rounded-8">
          <div className="d-md-flex align-items-md-center clearfix pb-1">
            <RatingBadge
              className="float-left float-md-none clickable px-md-0_75 mr-1"
              ratingScale={ratingScale}
              editorialRating={editorialRating}
              isScrollLink
            />
            <div>
              {isMobile ? (
                <TruncatedTextLite
                  className={classnames({ 'with-review-btn': !isUsed })}
                  text={editorialOverallText}
                  isHtmlText
                  additionalContent={[
                    { content: readFullReviewLink, className: 'd-inline' },
                    { content: ratingDisclaimerFragment, className: 'mt-1 no-line-height' },
                  ].filter(({ content }) => content)}
                  maxTextLength={250}
                  isInlineBtn
                  btnClassName="text-underline size-16"
                  btnTextLess="less"
                  btnTextMore="more"
                  btnTextA11y="about this model"
                  expandTrackingId="expand_verdict"
                  expandTrackingValue="more"
                />
              ) : (
                <Fragment>
                  <ContentFragment componentToUse="span" classes="mr-0_25">
                    {editorialOverallText}
                  </ContentFragment>
                  {readFullReviewLink}
                  {!!ratingDisclaimerFragment && <div className="no-line-height">{ratingDisclaimerFragment}</div>}
                </Fragment>
              )}
              {isUsed && (
                <div className="mt-0_75">
                  <Button
                    tag={ScrollLink}
                    to={MENU_HASH.REVIEWS}
                    scrollConfig={{ offset: SCROLL_OFFSET }}
                    color="outline-primary-b"
                    className="rounded-8 size-16 px-1 py-0_25"
                    data-tracking-id="view_edmunds_review"
                  >
                    Read full review
                  </Button>
                </div>
              )}
            </div>
          </div>
          {cardsRow}
        </div>
      )}
      {!editorialOverallText && cardsRow}
    </div>
  );
}

CoreHighlightsUI.propTypes = {
  params: CorePageParams.isRequired,
  className: PropTypes.string,
  styles: VehicleEntities.PopularStyles,
  sharedTrimName: PropTypes.string,
  editorialReview: EditorialReviewEntities.EditorialReview,
  reliabilityRating: PropTypes.number,
  segmentRating: TypeRankingsEntities.SegmentRating,
  consumerRatingsCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  prevYearConsumerRatingCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  stylesFeatures: PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity),
  fuelCostsData: VehicleEntities.StyleFuelCostData,
  evTestedRange: PropTypes.number,
  isUsed: PropTypes.bool,
  isPreprod: PropTypes.bool,
  isMobile: PropTypes.bool,
  isCore6454Enabled: PropTypes.bool,
  // used in getBestSegmentRating. Added here to avoid accidental removal
  // eslint-disable-next-line react/no-unused-prop-types
  submodelSlug: PropTypes.string,
};

CoreHighlightsUI.defaultProps = {
  className: 'px-1 pt-1',
  styles: null,
  sharedTrimName: null,
  editorialReview: null,
  reliabilityRating: null,
  segmentRating: null,
  consumerRatingsCount: null,
  prevYearConsumerRatingCount: null,
  stylesFeatures: null,
  fuelCostsData: null,
  evTestedRange: null,
  isUsed: false,
  isPreprod: false,
  isMobile: false,
  isCore6454Enabled: false,
  submodelSlug: null,
};

const mapStateToProps = state => ({
  isCore6454Enabled:
    ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'core-6454-est-ev',
      defaultVal: 'ctrl',
    }) === 'chal',
});

export const propsAreEqual = (
  prevProps,
  { vehicle, fuelCostsData, evTestedRange, params: { isElectric, isPluginHybrid } }
) =>
  !(fuelCostsData || (isElectric || isPluginHybrid)) ||
  !(isNumber(evTestedRange) || (!isElectric || vehicle.year < EV_TESTED_DATA_START_DATE));
export const CoreHighlights = flow(
  component =>
    connectToModel(component, {
      fuelCostsData: bindToPath(
        ({ styles, sharedTrimName, isElectric, isPluginHybrid }) =>
          !isElectric && !isPluginHybrid && styles && buildFuelCostsPath(getCurrentStyleId({ styles, sharedTrimName })),
        VehicleModel
      ),
      evTestedRange: bindToPath(
        ({ vehicle, styles, sharedTrimName, params: { isElectric } }) =>
          isElectric &&
          vehicle.year >= EV_TESTED_DATA_START_DATE &&
          styles &&
          buildEvTestedDataPath([getCurrentStyleId({ styles, sharedTrimName })]),
        VehicleModel,
        evTestedData => get(evTestedData, '[0].range') || 0
      ),
    }),
  component =>
    connectToModel(component, {
      sharedTrimName: bindToPath('sharedValues.trimName', PageModel),
      consumerRatingsCount: bindToPath(
        ({ params }) => ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath(params),
        ConsumerReviewsModel
      ),
      prevYearConsumerRatingCount: bindToPath(
        ({ params }) =>
          ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({
            ...params,
            year: params.year - 1,
          }),
        ConsumerReviewsModel
      ),
      stylesFeatures: bindToPath(
        ({ vehicle }) => FeatureSpecsPaths.buildPartialFeaturesPath(getParamsFromVehicle(vehicle)),
        VehicleModel
      ),
      styles: bindToPath(
        ({ vehicle }) => buildPopularStylesPath(getParamsFromVehicle(vehicle)),
        VehicleModel,
        popularStyles => orderBy(popularStyles, 'price.baseMSRP')
      ),
      segmentRating: bindToPath(
        ({ params: { make, model, year }, isUsed }) =>
          !isUsed && buildSegmentRatingsPath({ makeSlug: make, modelSlug: model, year }),
        TypeRankingsFeaturesModel,
        getBestSegmentRating
      ),
      reliabilityRating: bindToPath(
        ({ params: { make, model, year }, isUsed }) =>
          isUsed && getRepairPalDataPath({ makeSlug: make, modelSlug: model, year }),
        ReliabilityModel,
        ratings => get(ratings, 'reliabilityScore5Scale')
      ),
    }),
  component => connect(mapStateToProps)(component)
)(React.memo(CoreHighlightsUI, propsAreEqual));
